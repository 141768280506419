import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import classnames from 'classnames'
const Text = styled.div`
  & p {
    margin-bottom: 0;
  }
`

export default class Legal extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            data: contentfulGlobal {
              buttonLegal {
                g: childMarkdownRemark {
                  html
                }
              }
            }
          }
        `}
        render={({ data }) => (
          <Text
            style={{ fontSize: '0.8em' }}
            className={classnames(
              { 'text-white': this.props.dark },
              { 'text-muted': !this.props.dark },
              'text-small d-block py-2',
              this.props.className
            )}
            dangerouslySetInnerHTML={{
              __html: data.buttonLegal.g.html,
            }}
          />
        )}
      />
    )
  }
}
