import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'

import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

const Icon = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: url(${props => props.bg}), var(--white);
  background-size: 68% auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: 0 auto 1em auto;

  @media only screen and (max-width: 575px) {
    & {
      width: 40px;
      height: 40px;
      margin: 0 1em 0 0;
    }
  }
`

const Column = styled(Col)`
  @media only screen and (max-width: 575px) {
    & {
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 1em 1em;
    }
  }
`

export default class Icons extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            data: contentfulGlobal {
              icons {
                id
                title
                description
                file {
                  url
                }
              }
            }
          }
        `}
        render={({ data }) => {
          const options = [
            'Request a Callback',
            'Contact Us',
            'Request a Demo',
            'Contact Us',
          ]
          return (
            <div className="bg-dark py-5">
              <Container>
                <Row>
                  {data.icons.map((icon, i) => (
                    <Column sm="3" key={i}>
                      {' '}
                      <Icon
                        bg={icon.file.url}
                        title={icon.title}
                        style={{ cursor: 'pointer' }}
                        onClick={e => {
                          e.preventDefault()
                          navigate('/contact', {
                            state: {
                              selected: options[i],
                            },
                          })
                        }}
                      />
                      <h4
                        className="text-white text-md-center text-left  mb-0"
                        style={{ cursor: 'pointer' }}
                        onClick={e => {
                          e.preventDefault()
                          navigate('/contact', {
                            state: {
                              selected: options[i],
                            },
                          })
                        }}
                      >
                        {icon.title}
                      </h4>
                    </Column>
                  ))}
                </Row>
              </Container>
            </div>
          )
        }}
      />
    )
  }
}
