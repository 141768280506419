import React, { Component } from 'react'
import Helmet from 'react-helmet'

export default class Seo extends Component {
  render() {
    return (
      <Helmet>
        {this.props.title !== ' ' && <title>{this.props.title} </title>}
        {this.props.description !== ' ' && (
          <meta name="description" content={this.props.description} />
        )}
        {this.props.keywords !== ' ' && (
          <meta name="keywords" content={this.props.keywords} />
        )}
        {this.props.image && <meta name="image" content={this.props.image} />}
        {/* OpenGraph tags */}
        {this.props.url !== ' ' && (
          <meta property="og:url" content={this.props.url} />
        )}
        {this.props.title !== ' ' && (
          <meta property="og:title" content={this.props.title} />
        )}
        {this.props.type && (
          <meta property="og:type" content={this.props.type} />
        )}
        {this.props.post && <meta property="og:type" content="article" />}
        {this.props.description !== ' ' && (
          <meta property="og:description" content={this.props.description} />
        )}
        {this.props.image && (
          <meta property="og:image" content={this.props.image} />
        )}
        {/* Twitter Card tags */}
        
        {this.props.title !== ' ' && (
          <meta name="twitter:title" content={this.props.title} />
        )}
        {this.props.description !== ' ' && (
          <meta name="twitter:description" content={this.props.description} />
        )}
      </Helmet>
    )
  }
}
