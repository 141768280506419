import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
`

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;
`

class Youtube extends React.Component {
  state = {
    mounted: false,
  }
  componentDidMount() {
    this.setState({
      mounted: true,
    })
  }
  render() {
    return (
      <Wrapper>
        {this.state.mounted && (
          <Video
            src={`https://www.youtube.com/embed/${
              this.props.videoID
            }?rel=0&amp&showinfo=1;`}
            frameborder="0"
            modestbranding="1"
            allow="autoplay; encrypted-media"
            allowfullscreen
          />
        )}
      </Wrapper>
    )
  }
}

export default Youtube
